
  import { defineComponent, computed, ref } from "vue";
  import moment from 'moment'

  interface Props {
    data: { [key: string]: any },
  }

  export default defineComponent({
    props: {
      data: {
        type: Object,
        require: true
      },
    },

    setup(props: Props) {
      const getValue = () => {
        if (props.data.field.options?.contentFunction) {
          return props.data.field.options?.contentFunction(props.data)
        } else {
          return props.data.value
        }
      }

      const contentType = computed(() => props.data.field.options?.type)
      const iconClass = computed(() => {
        const ci = props.data.field.options?.colorInverted
        if (ci === true) {
          return props.data.value ? 'text-danger' : 'text-success'
        } else if (ci === false) {
          return props.data.value ? 'text-success' : 'text-danger'
        } else { return 'text-muted' }
      })

      const cellClass = computed(() => {
        const cC = props.data.field.options?.cellClassFunction
        if (cC) {return cC(props.data)} else {return ''}
      })

      const content = computed(() => {
        const value = getValue()
        if (contentType.value === 'date') {
          if (!!value) {
            return moment.utc(value).locale('de').format('ll')
          } else {
            return false
          }
        } else if (contentType.value === 'datetime') {
          if (!!value) {
            return moment(value).locale('de').format('lll')
          } else {
            return false
          }
        } else {
          return value
        }
      })

      // console.log('data: ', props.data.field.label, 'c: ', content.value, 'ct: ',contentType.value, 'data.value: ', props.data.value)

      return {
        contentType,
        content,
        iconClass,
        cellClass
      }
    }
  })
